import { Link } from "react-router-dom";
import { Helmet,HelmetProvider } from "react-helmet-async";

import React from "react";
const About = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Cashknock - About</title>
                </Helmet>
            </HelmetProvider>
            <main id="main">
                <div id="breadcrumbs" class="breadcrumbs">
                    <div class="container">
                        <div class="breadcrumb-bg">
                            <h2>About Cashknock | Providing Services With Reputation</h2>
                            <ol>
                                <li><Link to="/">Home</Link></li>
                                <li>About Us</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <section id="abt-page-1" class="abt-page-1">
                    <div class="container">
                        <div class="section-title" data-aos="fade-left">
                            <h2 data-aos="fade-up">Our Concept</h2>
                            <p>Somebody's squander is someone else's resource. We acknowledge every tech dream. Prevention is better than cure. So, try to be a part of prevention.</p>
                            <h2 data-aos="fade-up" class="mt-3">We include - Buy <i class="bi bi-arrow-right-short"></i> Sell <i class="bi bi-arrow-right-short"></i> Reuse <i class="bi bi-arrow-right-short"></i> Repeat.</h2>
                        </div>
                        <div class="row d-flex justify-content-center mt-5">
                            <div class="col-lg-6 col-md-6 col-12" data-aos="fade-up"> <img src="assets/img/about/abt-1.webp" alt="cashknock" /> </div>
                            <div class="col-lg-6 col-md-6 col-12" data-aos="fade-up">
                                <div class="abt-pg-content">
                                    <h3>When you reuse one phone </h3>
                                    <ul class="abt-page-listing">
                                        <li>You are saving 909 liters of water</li>
                                        <li>Saving of 258kg of raw material</li>
                                        <li>Saving of 80kg of CO' emissions</li>
                                    </ul>
                                    <p><strong>If you start applying this technique in your life it will be very beneficial for globe and better for pocket.</strong></p>
                                    <h3 class="mt-5">Our Platform - Sell Old Device</h3>
                                    <ul class="abt-page-listing">
                                        <li>Gift yourself a chance to enhance.</li>
                                        <li>Let your device to join new family.</li>
                                        <li>Let someone else to smile.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="abt-page-2" class="abt-page-2">
                    <div class="container">
                        <div class="row d-flex justify-content-center">
                            <div class="col-lg-6 col-md-6 col-12" data-aos="fade-up">
                                <div class="abt-pg-content">
                                    <h3>Our Goal</h3>
                                    <ul class="abt-page-listing">
                                        <li>Establishing a world where inovation doesn't need to cost the pocket or the sphere.</li>
                                        <li>Our passion is to change the lifecycle of every instrument. By rethink what we choose to do with it.</li>
                                    </ul>
                                    <h3 class="mt-5">Our Guarantee</h3>
                                    <ul class="abt-page-listing">
                                        <li>Assuring the 'right way' to business for the globe and the folks who live on it.</li>
                                        <li>We satisfy folks everyday gadget needs and dreams, which are sustainable and affordable.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12"> <img src="assets/img/about/abt-3.webp" alt="cashknock" /> </div>
                        </div>
                    </div>
                </section>
                <section id="services" class="services section-bg-2">
                    <div class="container">
                        <div class="section-title text-white" data-aos="fade-up">
                            <h2 class="text-white">Just Few Steps To Sell Your Phone</h2>
                            <p>Book a free pickup from your home or work at a time slot as per your convenience</p>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-12" data-aos="fade-up">
                                <div class="icon-box">

                                    <div class="step-img"><img src="assets/img/steps/1.webp" alt="cashknock category" />
                                        <div class="c-anim">
                                            <div class="outerCircle"></div>
                                            <div class="innerCircle"></div>
                                            <div class="icon"></div>
                                        </div>
                                    </div>
                                    <h4 class="title"><a href="">Get Quotation</a></h4>
                                    <p class="description text-white">Select your device & tell us about its current condition, and our advanced AI tech will tailor make the perfect price for you.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12" data-aos="fade-up">
                                <div class="icon-box">
                                    <div class="step-img"><img src="assets/img/steps/2.webp" alt="cashknock category" />
                                        <div class="c-anim">
                                            <div class="outerCircle"></div>
                                            <div class="innerCircle"></div>
                                            <div class="icon"></div>
                                        </div>
                                    </div>
                                    <h4 class="title"><a href="">Select Location & Schedule Pickup</a></h4>
                                    <p class="description text-white">Free pickup service from a location of your choice and convenience. We'll get in touch instantly after the price confirmation.</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-12" data-aos="fade-up">
                                <div class="icon-box">
                                    <div class="step-img"><img src="assets/img/steps/3.webp" alt="cashknock category" />
                                        <div class="c-anim">
                                            <div class="outerCircle"></div>
                                            <div class="innerCircle"></div>
                                            <div class="icon"></div>
                                        </div>
                                    </div>
                                    <h4 class="title"><a href="">Get Paid Instantly</a></h4>
                                    <p class="description text-white">Receive payment before your phone leaves your hand. Mode of payment? Take your pick.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
export default About