import { Link } from "react-router-dom";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
const Support = () => {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Cashknock - Support</title>
                </Helmet>
            </HelmetProvider>
            <main id="main">
                <div id="breadcrumbs" class="breadcrumbs">
                    <div class="container">
                        <div class="breadcrumb-bg">
                            <h2>Frequently Asked Questions</h2>
                            <ol>
                                <li><Link to="/">Home</Link></li>
                                <li>Frequently Asked Questions</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <section id="faq" class="faq bg-body-secondary">
                    <div class="container">

                        <div class="section-title">
                            <h2 data-aos="fade-up">F.A.Q</h2>
                            <p data-aos="fade-up">Frequently Asked Questions</p>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="faq-list p-0">
                                    <ul>
                                        <li data-aos="fade-up">
                                            <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-1">Should I delete all data from phone before selling the phone to you? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                            <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
                                                <p>We recommend you delete all the personal data and also remove the SIM and memory card form your phone. But, just in case you haven’t, then it’s not a problem at all. Because before going ahead with any further procedure, we ensure that our technical expert has wiped out all the available data from the device.</p>
                                            </div>
                                        </li>

                                        <li data-aos="fade-up" data-aos-delay="100">
                                            <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" class="collapsed">Is it safe to sell my phone on CashKnock? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                            <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
                                                <p>Yes, it is totally safe.
                                                </p>
                                            </div>
                                        </li>

                                        <li data-aos="fade-up" data-aos-delay="200">
                                            <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" class="collapsed">Will you take Dead mobiles too? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                            <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
                                                <p>Yes, we take broken and dead mobiles if you are selling mobile phone on Cashknock. However, it might affect the overall quote of the phone.</p>
                                            </div>
                                        </li>

                                        <li data-aos="fade-up" data-aos-delay="300">
                                            <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" class="collapsed">Are there any models of phone you do not buy?<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                            <div id="faq-list-4" class="collapse" data-bs-parent=".faq-list">
                                                <p>No, Cashknock buys almost all phones; please find a list of handsets on our website or the app we buy.</p>
                                            </div>
                                        </li>

                                        <li data-aos="fade-up" data-aos-delay="400">
                                            <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" class="collapsed">How much time do you take to arrange the pickup?<i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                            <div id="faq-list-5" class="collapse" data-bs-parent=".faq-list">
                                                <p>Cashknock tries to get the pickup done within the next 24-48 hours after placing the order, except Sunday or public holidays.</p>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>


                            <div class="col-md-6">
                                <img src="assets/img/faq.webp" alt="cashknock category" />
                            </div></div>

                    </div>
                </section>




            </main>
        </>
    )
}
export default Support