import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate, useLocation, Link } from 'react-router-dom';

import axios from 'axios';
import Loader from "../components/Loader";
const SellBrandwise = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // const [searchParams] = useSearchParams();
    // const id = searchParams.get('category_id');

    const searchParams = useLocation();
    const { state } = searchParams;

    const navigate = useNavigate();

    const navigateToBrandModel = (brand_id, slug) => {
        const modelData = { category_id: state.category_id, brand_id: brand_id };
        navigate(`/` + slug, { state: modelData });
    };

    useEffect(() => {
        let isMounted = true; // Flag to track whether the component is mounted

        const fetchData = async () => {
            try {
                const response = await axios.post('https://cashknock.in/admin/api/getBrandByCategoryId', { category_id: state.category_id });
                if (isMounted) {
                    setData(response.data.data);
                    setLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                    setLoading(false);
                }
            }
        };

        



        fetchData();
        // Cleanup function (optional)
        return () => {
            isMounted = false; // Mark the component as unmounted
        };



    }, []);
    console.log(data)


    //search for sell-brandwise 
    const [searchInput, setSearchInput] = useState("");
    const handleSearchInput = (e) => {
        setSearchInput(e.target.value);
    }
    const [brandData, setBrandData] = useState();
    const handleNewData = () => {
        if (searchInput) {
            const updatedBrandData = data?.filter((Element) => {
                // return searchInput.toLocaleLowerCase() == Element.brand.toLocaleLowerCase();
                return Element.brand.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase().replaceAll(' ', ''));
            })
            setBrandData(updatedBrandData);
        } else {
            setBrandData(data);
        }
    }
    useEffect(() => {
        handleNewData();
    }, [searchInput])

    useEffect(() => {
        setBrandData(data);
    }, [data]);


    if(loading){
        return <Loader/>
    }

    return (
        <main id="main">
            <div id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <div class="breadcrumb-bg">
                        <div class="row d-flex align-items-center">
                            <div class="col-lg-7 col-md-8 col-12">
                                <h2>Select Your Model</h2>
                                <ol>
                                    <li><Link to="/">Home</Link></li>
                                    <li>Sell Your Old Phone</li>
                                    <li>Select Your Model</li>
                                </ol>
                            </div>
                            <div class="col-lg-5 col-md-4 col-12">
                                <div class="device-brand"><span>
                                    <button onClick={handleNewData}><i class="bi bi-search"></i></button>
                                    <input type="search" placeholder="Search your brand" onChange={handleSearchInput} value={searchInput} />
                                </span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* {console.log(data[0].brand)}; */}


            {brandData?.length > 0 ?
                <section id="d-gallery" class="d-gallery">
                    <div class="container" data-aos="fade-up">

                        <div class="row device-gallery g-3">
                            {
                                loading == false && brandData?.map(option => (
                                    <div class="col-lg-2 col-md-3 col-6">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div class="brand-box">
                                                <a href="javascript:void(0)" onClick={() => navigateToBrandModel(option.id, option.category_name.toLowerCase() + "/" + option.url)}>
                                                    <img src={option.image} alt="cashknock mobile scroll" /></a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </section>

                : <div className="d-flex align-items-center justify-content-center">
                    <img src='../assets/img/no-data.webp' className='img-fluid' style={{width:"25rem"}} />
                </div>
                
                
            }

            <section id="about" class="about">
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <div class="col-xl-9 col-lg-10">
                            <div class="section-title" data-aos="fade-up">
                                <h2>Why Us?</h2>
                                <p>We are a dedicated bunch of professionals aligned with your goal of maximum satisfaction.</p>

                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-lg-3 col-md-4 col-6">
                            <div class="y-feature"> <img src="assets/img/why-us/1.png" alt="why-us feature" />
                                <h4>One-Stop Solution</h4>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                            <div class="y-feature"> <img src="assets/img/why-us/2.png" alt="why-us feature" />
                                <h4>Trained Professionals</h4>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                            <div class="y-feature"> <img src="assets/img/why-us/3.png" alt="why-us feature" />
                                <h4>Amazing Prices</h4>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                            <div class="y-feature"> <img src="assets/img/why-us/4.png" alt="why-us feature" />
                                <h4>Quick & Hassle-free Payment</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main >
    )
}
export default SellBrandwise