import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Loader from '../components/Loader';
import { Helmet,HelmetProvider } from 'react-helmet-async';
const AllCategories = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const navigate = useNavigate();

    const navigateToDetails = (id) => {
        const paramsData = { category_id: id};
        navigate(`/sell-brandwise`,{state:paramsData});
    };

    useEffect(() => {
        let isMounted = true; // Flag to track whether the component is mounted

        const fetchData = async () => {
            try {
                const response = await axios.get('https://cashknock.in/admin/api/getHomePageData');
                // console.log("banners:",response.data.data[0]);
                if (isMounted) {
                    setData(response.data.data[0]);
                    setLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                    setLoading(false);
                }
            }
        };



        fetchData();
        // Cleanup function (optional)
        return () => {
            isMounted = false; // Mark the component as unmounted
        };
    }, []);


    if(loading){
        return <Loader/>
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Cashknock - All Categories</title>
                </Helmet>
            </HelmetProvider>
            <main id="main">
                <div id="breadcrumbs" class="breadcrumbs">
                    <div class="container">
                        <div class="breadcrumb-bg">
                            <div class="row d-flex align-items-center">
                                <div class="col-lg-7 col-md-8 col-12">
                                    <h2>Select Your Model</h2>
                                    <ol>
                                        <li><Link to="/">Home</Link></li>
                                        <li>Categories</li>
                                        <li>All Categories</li>
                                    </ol>
                                </div>
                                {/* <div class="col-lg-5 col-md-4 col-12">
                                    <div class="device-brand"><span>
                                        <button><i class="bi bi-search"></i></button>
                                        <input type="search" placeholder="Search your device" />
                                    </span></div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <section id="d-gallery" class="d-gallery">
                    <div class="container" data-aos="fade-up">
                        <div className="section-title">
                            <h2 data-aos="fade-up">Sell Old Gadgets</h2>
                            <p data-aos="fade-up">Selling gadgets made easier! Knock it out! Knock out your cell phones, laptops or other devices</p>
                        </div>
                        <div class="row device-gallery g-4">
                            {
                                loading == false && data.categories && data.categories.map(option => (
                                    <div class="col-lg-2 col-md-3 col-6 ">
                                        <div class="categ-card">
                                            <a href='javascript:void(0)' onClick={(e) => {
                                                e.preventDefault(); // Prevent the default anchor click behavior
                                                navigateToDetails(option.id);
                                            }} >
                                                <div>
                                                <img src={option.image} alt="cashknock category" />
                                                </div>
                                                <h4 style={{ fontSize: 'small', textAlign: 'center' }}>{option.category}</h4>
                                            </a>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>

                <section id="about" class="about">
                    <div class="container">
                        <div class="row d-flex justify-content-center">
                            <div class="col-xl-9 col-lg-10">
                                <div class="section-title" data-aos="fade-up">
                                    <h2>Why Us?</h2>
                                    <p>We are a dedicated bunch of professionals aligned with your goal of maximum satisfaction.</p>

                                </div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-lg-3 col-md-4 col-6">
                                <div class="y-feature"> <img src="assets/img/why-us/1.png" alt="why-us feature" />
                                    <h4>One-Stop Solution</h4>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-6">
                                <div class="y-feature"> <img src="assets/img/why-us/2.png" alt="why-us feature" />
                                    <h4>Trained Professionals</h4>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-6">
                                <div class="y-feature"> <img src="assets/img/why-us/3.png" alt="why-us feature" />
                                    <h4>Amazing Prices</h4>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-6">
                                <div class="y-feature"> <img src="assets/img/why-us/4.png" alt="why-us feature" />
                                    <h4>Quick & Hassle-free Payment</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main >

        </>
    )
}

export default AllCategories;