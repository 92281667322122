import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Select } from "antd";
import axios from "axios";

import Loader from '../components/Loader';


const Header = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMounted, setIsMounted] = useState(true);
    const [refreshKey, setRefreshKey] = useState(0);
    const [cateSerach, setCateSerach] = useState('');
    const [TextSerach, setTextSerach] = useState('');


    useEffect(() => {
        // Flag to track whether the component is mounted
        fetchData();
        // Cleanup function (optional)
        return () => {
            setIsMounted(false); // Mark the component as unmounted
        };
    }, [isMounted, refreshKey]);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://cashknock.in/admin/api/getHomePageData');
            if (isMounted) {
                setData(response.data.data[0]);
                setLoading(false);
            }
        } catch (error) {
            if (isMounted) {
                setError(error);
                setLoading(false);
            }
        }
    };

    const navigate = useNavigate();

    const navigateToDetails = (id, brandid, slug) => {
        const paramsData = { category_id: id, brand_id: brandid, partialSlug: slug };
        navigate(slug, { state: paramsData });
        setRefreshKey(prevKey => prevKey + 1);
    };

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const handleChange = (value) => {
        setCateSerach(value);
    };

    let value;
    const handleKeypress = (event) => {
         value  = event.target.value;
        setTextSerach(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const paramsData = { state: TextSerach, category_id: cateSerach };
        // console.log(paramsData);
        navigate('/search', { state: paramsData })
    };


    const logout = () => {
        localStorage.clear();
        navigate(`/`);
    }



    // mobile toggle menu
    const handleMobileView = (e) => {
        if (e.target.classList.contains('mobile-nav-toggle')) {
            document.getElementById('navbar').classList.toggle('navbar-mobile');
            e.target.classList.toggle('bi-list');
            e.target.classList.toggle('bi-x');
        } else {
            document.getElementById('navbar').classList.remove('navbar-mobile');
            document.querySelector(".mobile-nav-toggle").classList.add("bi-list");
            document.querySelector(".mobile-nav-toggle").classList.remove("bi-x");
        }
    }

    const handleMobileDropdown = (e) => {
        if (e.target.matches('.navbar .dropdown > a') || e.target.closest('.navbar .dropdown > a')) {
            if (document.getElementById('navbar').classList.contains('navbar-mobile')) {
                e.preventDefault();
                e.currentTarget.nextElementSibling.classList.toggle('dropdown-active');
            }
        }
    }

   

    return (
        <>
            <section id="topbar">
                <div className="container d-flex align-items-center">
                    <div className="logo">
                        <Link to="/">
                            <img src="../assets/img/logo.webp" alt="cashknock logo" className="img-fluid" />
                        </Link>
                    </div>
                    <div className="searchbar d-none d-lg-block">
                        <div className="site-search">
                            <div className="product_search">
                                <form className="search_form_1" role="search" method="get" onSubmit={handleSubmit}>
                                    <div className="select_mate" data-mate-select="active">
                                        <Select
                                            defaultValue="All categories"
                                            variant="borderless"
                                            style={{
                                                width: "100%",
                                                margin: '10px',
                                                border: '0px'
                                            }}
                                            onChange={handleChange}
                                        >
                                            {data.categories && data.categories.map(option => (
                                                <Select.Option key={option.id} value={option.id}>
                                                    {option.category}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div class="outer">
                                        <div class="inner"></div>
                                    </div>
                                    <input type="search" placeholder="Search for products" name="s" value={TextSerach} onChange={handleKeypress} />
                                    <button type="submit" ><i className="bi bi-search"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="cta d-flex align-items-center">
                        {
                            localStorage.getItem('login-token') && localStorage.getItem('login-token') != '' ?
                                <>
                                    <Link  to= "/user-profile" className="acnt-btn" ><i className="bi bi-person-fill"></i>{localStorage.getItem('name').split(' ')[0]}</Link>
                                    <a href="javascript:void(0)" className="acnt-btn" onClick={() => logout()}><i class="bi bi-box-arrow-right"></i>Logout</a>
                                </>
                                :
                                <>
                                    <button type="button" className="acnt-btn" data-bs-toggle="modal" data-bs-target="#registerModal"><i className="bi bi-person-fill"></i> Register</button>
                                    <button type="button" className="acnt-btn" data-bs-toggle="modal" data-bs-target="#loginModel"><i className="bi bi-person-fill"></i> Login</button>
                                    
                                    {/* <button type="button" className="acnt-btn acnt-btn-new" data-bs-toggle="modal" data-bs-target="#loginModel"><i className="bi bi-person-fill"></i> Login</button> */}
                                </>
                        }
                    </div>
                </div>
            </section>


            <header id="header">
                <div className="container d-flex align-items-center">
                    <nav id="navbar" className="navbar">
                        <ul>
                            <li >
                                <Link to="/" onClick={handleMobileView}>
                                    <span >Home</span>
                                </Link>
                            </li>
                            {
                                data.categories && data.categories.map(option => (
                                    (option.display_at_navigation === 1) ?
                                        <li className="dropdown">
                                            <a href="" onClick={handleMobileDropdown}>
                                                <span>Sell {option.category}</span>
                                            <i className="bi bi-chevron-down"></i>
                                            </a>
                                            {option.brands ?
                                                <ul style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                                                    {
                                                        option.brands && option.brands.map(brands => (
                                                            <li onClick={handleMobileView}>
                                                                <a href="javascript:void(0)" onClick={() => navigateToDetails(option.id, brands.id, option.url + "/" + brands.url)}>{brands.brand}
                                                                </a>
                                                            </li>))
                                                    }

                                                </ul>
                                                : null
                                            }
                                        </li>
                                        : null
                                ))}
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle" onClick={handleMobileView}></i>
                    </nav>
                    <div id="mob-search" className="d-lg-none d-md-none d-block"> <Link to="/search"> <i className="bi bi-search"></i> Search </Link>
                        <div id="search"> <span className="close">X</span>
                            <form role="search" id="searchform" action="/search" method="get">
                                <input value="" name="q" type="search" placeholder="type to search" />
                            </form>
                        </div>
                    </div>
                </div>
            </header>


        </>
    )
}

export default Header;