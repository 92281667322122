import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import { Radio } from 'antd';


const Footer = () => {

    //OTP BOX
    const [otp, setOTP] = useState(new Array(6).fill(""));
    const handleOTPfill = (e, index) => {
        if (isNaN(e.target.value)) return false;

        setOTP([...otp.map((data, ind) => (index === ind ? e.target.value : data))])

        if (e.target.value && e.target.nextSibling) {
            e.target.nextSibling.focus();
        }

    }
    // console.log(otp);
    const OTPwithComma = otp.toString();
    const OTPwithoutComma = OTPwithComma.replace(/,/g, "");
    // console.log(OTPwithoutComma);


    ////register model////
    const initialStateRegister = {
        name: "",
        email: "",
        mobile_no: "",
        agree_terms: false
    };
    const [values, setValues] = useState(initialStateRegister);
    const handleInputChange = (event) => {
        event.preventDefault();
        const { name, value, type, checked } = event.target;
        setValues((values) => ({
            ...values,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const [submitted, setSubmitted] = useState(false);
    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);


    const [registerUI, setRegisterUI] = useState(true);

    // generateOTP for register
    const handleRegisterDetails = async (e) => {
        e.preventDefault();
        try {
            if (values.name === "" || values.email === "" || values.mobile_no === "") {
                setRegisterInputEmpty(true);
                return;
            }
            setLoading(true);
            const response = await axios.post("https://cashknock.in/admin/api/register-otp", values);
            setLoading(false);
            setSubmitted(false);
            if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.success(response.data.message);
                setRegisterUI(false);
            }
        } catch (error) {
            if (error.response) {
                setLoading(false);
                setSubmitted(false);
                console.log(error)
                showToast(error.response.data.data[0]);
            } else if (error.request) {
                setLoading(false);
                setSubmitted(false);
                showToast("It seems server is busy.");
            } else {
                console.error('Form submission error:', error);
            }
        }
    }

    //validate OTP at register
    const handleRegisterOTPValidation = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("https://cashknock.in/admin/api/verify-otp", {
                email: values.email,
                otp: OTPwithoutComma
            });
            setLoading(false);
            setSubmitted(false);
            console.log(response.data);
            if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.success(response.data.message);
                console.log(response.data.data);
                localStorage.setItem('login-token', response.data.data.token);
                localStorage.setItem('name', response.data.data.name);
                localStorage.setItem('email', response.data.data.email);
                localStorage.setItem('user_id', response.data.data.user_id);
                resetForm();
                window.location.reload();
            }

        } catch (error) {
            if (error.response) {
                setLoading(false);
                setSubmitted(false);
                console.log(error)
                showToast(error.response.data.data[0]);
            } else if (error.request) {
                setLoading(false);
                setSubmitted(false);
                showToast("It seems server is busy.");
            } else {
                console.error('Form submission error:', error);
            }
        }
    }




    ////login model////

    const initialStateLogin = {
        "mobile_email": "",
        "type": ""
    };

    const [loginValues, setLoginValues] = useState(initialStateLogin);
    const [loginUI, setLoginUI] = useState(true);
    const [loginCheckBox, setLoginCheckBox] = useState("Email");
    const [loginInputEmpty, setLoginInputEmpty] = useState(false);
    const [otpLoading, setOtpLoading] = useState(false);
    const [loginType, setLoginType] = useState("Email");

    const handleLoginInputChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setLoginValues((lValues) => ({
            ...lValues,
            [name]: value
        }));
    };



    //generateOTP for login
    const handleLoginDetails = async (e) => {
        e.preventDefault();
        try {
            if (loginValues.mobile_email === "") {
                setLoginInputEmpty(true);
                return;
            }

            setOtpLoading(true);
            const response = await axios.post("https://cashknock.in/admin/api/userlogin", {
                "mobile_email": loginValues.mobile_email, type: loginType
            });


            console.log(response.data);
            setOtpLoading(false);
            setLoading(false);
            setSubmitted(false);

            if (response.data.status === 0) {
                toast.error(response.data.message)
            } else {
                setLoginUI(false);
                toast.success(response.data.message)
            }
        } catch (error) {
            setLoginUI(true);
            if (error.response) {
                setLoading(false);
                setSubmitted(false);
                showToast(error.response.data.data[0]);
            } else if (error.request) {
                setLoading(false);
                setSubmitted(false);
                showToast("It seems server is busy.");
            }
            showToast(error.data.data[0]);
            console.log(error);
        }
    }

    //validate OTP
    const handleLoginOTPValidation = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("https://cashknock.in/admin/api/verify-login-otp", {
                mobile_email: loginValues.mobile_email,
                otp: OTPwithoutComma
            });
            setLoading(false);
            setSubmitted(false);

            if (response.data.status === 0) {
                toast.error(response.data.message);
            } else {
                toast.success(response.data.message);
                localStorage.setItem('login-token', response.data.data.token);
                localStorage.setItem('name', response.data.data.name);
                localStorage.setItem('email', response.data.data.email);
                localStorage.setItem('user_id', response.data.data.user_id);
                setIsSuccess(true);
                resetForm();
                window.location.reload();
            }


        } catch (error) {
            if (error.response) {
                setLoading(false);
                setSubmitted(false);
                showToast(error.response.data.data[0]);
            } else if (error.request) {
                setLoading(false);
                setSubmitted(false);
                showToast("It seems server is busy.");
            }
        }
    }


    // Function to show the toast
    const showToast = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })
    };

    const resetForm = () => {
        setValues(initialStateRegister);
    };

    // Handle close button click
    const handleClose = () => {
        setRegisterInputEmpty(false);
        setLoginInputEmpty(false);
        setRegisterUI(true)
        setLoginUI(true);
        setIsSuccess(false); // Clear success status
        resetForm();         // Reset the form
        setLoginValues({ mobile_email: "" });
    };

    const [isOpen, setIsOpen] = useState(false);
    const handleLinkClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsOpen(false);
    };

    const [registerInputEmpty, setRegisterInputEmpty] = useState(false);


    const options = [
        { label: 'Email', value: 'Email' },
        { label: 'Phone', value: 'Phone' },
    ];

    const handleLoginType = ({ target: { value } }) => {
        console.log(value);
        setLoginInputEmpty(false)
        setLoginCheckBox(value)
        setLoginType(value);
        setLoginValues(initialStateLogin);
    };

    return (
        <>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12 footer-contact">
                                <div className="f-logo"> <Link to="/">
                                    <img src="../assets/img/f-logo.webp" alt="cashknock logo" className="img-fluid" /></Link> </div>
                                <p>We offer high-quality service and the best delivery service, and the gadget market you can blindly trust</p>
                            </div>
                            <div className="col-lg-2 col-md-6 col-6 footer-links">

                                <h4>Useful Links</h4>
                                <ul>
                                    {/* <li><i className="bx bx-chevron-right"></i> <a href="about.html">About Us</a></li>
                                     */}
                                    <li onClick={handleLinkClick}>
                                        <i className="bx bx-chevron-right"></i>
                                        <Link to="/about" className="back-to-top-1">About</Link>
                                    </li>
                                    <li onClick={handleLinkClick}><i className="bx bx-chevron-right"></i>
                                        <Link to="/contact" className="back-to-top-1">Contact Us</Link>
                                    </li>
                                    <li onClick={handleLinkClick}><i className="bx bx-chevron-right"></i>
                                        <Link to="/support" className="back-to-top-1">Support</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-6 col-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li onClick={handleLinkClick}><i className="bx bx-chevron-right"></i>
                                        <Link to="/terms-of-service" className="back-to-top-1">Terms of Service</Link>
                                    </li>
                                    <li onClick={handleLinkClick}><i className="bx bx-chevron-right"></i>
                                        {/* <a href="privacy.html">Privacy Policy</a> */}
                                        <Link to="/privacy" className="back-to-top-1">Privacy Policy</Link>
                                    </li>
                                    <li onClick={handleLinkClick}><i className="bx bx-chevron-right"></i> <a href="#">For Teams Only</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 footer-links">
                                <h4>Contact Us</h4>
                                <div className="cont-details">
                                    <p><i className="bi bi-pin-map-fill"></i> D-54, Second Floor, Sector - 2, Noida</p>
                                    <a href="tel:+919876543210"><i className="bi bi-telephone-inbound-fill"></i> +91 8800390953</a> <a href="mail:support@cashknock.com"><i className="bi bi-envelope-at-fill"></i> support@cashknock.com</a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container d-lg-flex py-3 align-items-center">
                    <div className="me-lg-auto text-center text-lg-start">
                        <div className="copyright"> Copyright 2024<strong><span> Cashknock</span></strong> | All Rights Reserved | <span>Design & Developed By <a href="https://www.coderworldlabs.com/">CoderWorld Labs</a></span> </div>
                    </div>
                    <div className="social-links text-center text-lg-right pt-3 pt-lg-0"> <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a> <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a> <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a> <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a> <a href="#" className="linkedin"><i className="bx bxl-linkedin"></i></a> </div>
                </div>
            </footer>

            <div class="modal login" id="registerModal">
                <div class="modal-dialog">
                    <div class="modal-content">
                        {
                            registerUI ?
                                <form className="register-form" onSubmit={handleRegisterDetails}>
                                    <div class="modal-header">
                                        <h4 class="modal-title">Login/Signup</h4>
                                        <button type="button" class="btn-close btn-close btn-close-white" data-bs-dismiss="modal" onClick={handleClose}></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-6 col-12 d-flex justify-content-center align-items-center"> <img src="assets/img/login.webp" alt="login" /> </div>
                                            <div class="col-lg-7 col-md-6 col-12 login-inputs">
                                                <div class="form-group mt-3 mt-md-0">
                                                    {!valid && (
                                                        <>
                                                            {/* <label>Enter your name</label> */}
                                                            <input
                                                                class="form-field"
                                                                type="text"
                                                                placeholder="Name"
                                                                name="name"
                                                                value={values.name}
                                                                defaultValue={values.name}
                                                                onChange={handleInputChange}
                                                            />
                                                        </>
                                                    )}
                                                    {submitted && !values.name && (
                                                        <span className="form-error">Please enter a first name</span>
                                                    )}
                                                </div>
                                                <div class="form-group mt-3 mt-md-0">
                                                    {!valid && (
                                                        <>
                                                            {/* <label>Enter your email i'd</label> */}
                                                            <input
                                                                class="form-field"
                                                                type="email"
                                                                placeholder="Your Email I'd"
                                                                name="email"
                                                                value={values.email}
                                                                defaultValue={values.email}
                                                                onChange={handleInputChange}
                                                            />
                                                        </>
                                                    )}
                                                    {submitted && !values.email && (
                                                        <span className="form-error">Please enter email</span>
                                                    )}
                                                </div>
                                                <div class="form-group mt-3 mt-md-0">
                                                    {!valid && (
                                                        <>
                                                            {/* <label>Mobile Number</label> */}
                                                            <input
                                                                class="form-field"
                                                                type="text"
                                                                placeholder="Your Mobile Number"
                                                                name="mobile_no"
                                                                value={values.mobile_no}
                                                                defaultValue={values.mobile_no}
                                                                onChange={handleInputChange}
                                                            />
                                                        </>
                                                    )}
                                                    {submitted && !values.mobile_no && (
                                                        <span className="form-error">Please enter your contact number</span>
                                                    )}
                                                </div>
                                                {
                                                    registerInputEmpty
                                                        ?
                                                        <>
                                                            {
                                                                loading ?
                                                                    <h6 style={{ color: "green" }}>OTP sending...</h6>
                                                                    : <h6 style={{ color: "red" }}>please fill all the fields*</h6>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                loading ?
                                                                    <h6 style={{ color: "green" }}>OTP sending...</h6>
                                                                    : null
                                                            }
                                                        </>

                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div class="modal-footer login-footer">
                                        <div class="mb-3 form-check">
                                            {!valid && (
                                                <>
                                                    {/* <label>Confirm Password</label> */}
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        name="agree_terms"
                                                        defaultValue={0}
                                                        value={values.agree_terms}
                                                        onChange={handleInputChange}
                                                        checked
                                                        style={{ backgroundColor: "#14561d" }}
                                                    />
                                                </>
                                            )}
                                            {submitted && !values.agree_terms && (
                                                <span className="form-error">Required!</span>
                                            )}
                                            {/* <input type="checkbox" class="form-check-input" id="exampleCheck1" /> */}
                                            <label class="form-check-label" for="exampleCheck1">I agree to the <a href="/terms-of-service" >Terms and Conditions</a> & <a href="/privacy" >Privacy Policy</a></label>
                                        </div>
                                        {
                                            loading ? <button type="submit" class="modal-c-btn pe-none" style={{ "cursor": "not-allowed" }}>Get OTP</button> : <button type="submit" class="modal-c-btn" >Get OTP</button>
                                        }

                                    </div>
                                </form>
                                :
                                <form className="register-form" >
                                    <div class="modal-header">
                                        <h4 class="modal-title">Login/Signup</h4>
                                        <button type="button" class="btn-close btn-close btn-close-white" data-bs-dismiss="modal" onClick={handleClose}></button>
                                    </div>
                                    <div class="modal-body">
                                        <div class="row">
                                            <div class="col-lg-5 col-md-6 col-12 d-flex justify-content-center align-items-center"> <img src="assets/img/login.webp" alt="login" /> </div>
                                            <div class="col-lg-7 col-md-6 col-12 login-inputs">

                                                <div id="otp">
                                                    <h5>A code has been sent to your email</h5>
                                                    <label>Please enter the one time password to verify your account</label>
                                                    <div class="d-flex justify-content-start">
                                                        {
                                                            otp.map((data, ind) =>
                                                                <input class="text-center form-control rounded" type="text" id="first" maxlength="1" value={data} onChange={(e) => handleOTPfill(e, ind)}
                                                                    autoComplete="off" />)
                                                        }
                                                    </div>
                                                    {
                                                        otpLoading
                                                            ?
                                                            <h6 style={{ color: "green" }}>OTP resending...</h6>
                                                            :
                                                            <>
                                                                <label style={{ color: 'green' }}>Didn't get the code? <button class="resend-btn" onClick={handleRegisterDetails}>Resend</button></label>
                                                                <div class="mt-4">
                                                                    <button class="validate" onClick={handleRegisterOTPValidation}>Signup</button>
                                                                </div>
                                                            </>

                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </form>
                        }

                    </div>
                </div>
            </div>

            <div class="modal login" id="loginModel">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <form className="register-form" >
                            <div class="modal-header">
                                <h4 class="modal-title">Login</h4>
                                <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" onClick={handleClose} ></button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-lg-5 col-md-6 col-12 d-flex justify-content-center align-items-center">
                                        <img src="assets/img/login.webp" alt="login" />
                                    </div>
                                    <div class="col-lg-7 col-md-6 col-12 login-inputs">

                                        {
                                            loginUI
                                                ?
                                                <>
                                                    <div className="custom-radio">
                                                        <Radio.Group
                                                            options={options}
                                                            onChange={handleLoginType}
                                                            value={loginCheckBox}
                                                            optionType="button"
                                                            buttonStyle="solid"
                                                        />
                                                    </div>
                                                    {
                                                        loginCheckBox === "Email"
                                                            ?
                                                            <>
                                                                <div class="form-group mt-3 mt-md-0 login-new">
                                                                    {!valid && (
                                                                        <>
                                                                            <h6 style={{ margin: "10px 0", }}>Enter your email</h6>
                                                                            <input
                                                                                class="form-field"
                                                                                type="text"
                                                                                placeholder="Enter your email"
                                                                                name="mobile_email"
                                                                                value={loginValues.mobile_email}
                                                                                defaultValue={loginValues.mobile_email}
                                                                                onChange={handleLoginInputChange}
                                                                                autoComplete="off"
                                                                            />
                                                                        </>
                                                                    )}
                                                                    {submitted && !loginValues.lemail && (
                                                                        <span className="form-error">Enter your email</span>
                                                                    )}
                                                                </div>
                                                                {
                                                                    loginInputEmpty ?
                                                                        <>

                                                                            {
                                                                                otpLoading === true ? <h6 style={{ color: "green" }}>OTP Sending...</h6>
                                                                                    :
                                                                                    <div>
                                                                                        <h6 style={{ color: "red" }}>Please enter the email*</h6>
                                                                                        <div class="modal-footer login-footer login-new-footer">
                                                                                            <button type="submit" className="modal-c-btn " onClick={handleLoginDetails}>Get OTP</button>
                                                                                        </div>
                                                                                    </div>
                                                                            }

                                                                        </>
                                                                        :
                                                                        <>

                                                                            {
                                                                                otpLoading === true ? <h6 style={{ color: "green" }}>OTP Sending...</h6>
                                                                                    : <div class="modal-footer login-footer login-new-footer">
                                                                                        <button type="submit" className="modal-c-btn" onClick={handleLoginDetails}>Get OTP</button>
                                                                                    </div>
                                                                            }
                                                                        </>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                <div class="form-group mt-3 mt-md-0 login-new">
                                                                    {!valid && (
                                                                        <>
                                                                            <h6 style={{ margin: "10px 0", }}>Enter your phone</h6>
                                                                            <input
                                                                                class="form-field"
                                                                                type="text"
                                                                                placeholder="Enter your mobile number"
                                                                                name="mobile_email"
                                                                                value={loginValues.mobile_email}
                                                                                defaultValue={loginValues.mobile_email}
                                                                                onChange={handleLoginInputChange}
                                                                                autoComplete="off"
                                                                            />
                                                                        </>
                                                                    )}
                                                                    {submitted && !loginValues.lemail && (
                                                                        <span className="form-error">Enter your mobile no.</span>
                                                                    )}
                                                                </div>
                                                                {
                                                                    loginInputEmpty ?
                                                                        <>
                                                                            {
                                                                                otpLoading ?
                                                                                    <h6 style={{ color: "green" }}>OTP Sending...</h6>
                                                                                    :
                                                                                    <>
                                                                                        <h6 style={{ color: "red" }}>Please enter the mobile no*</h6>
                                                                                        <div class="modal-footer login-footer login-new-footer">
                                                                                            <button type="submit" className="modal-c-btn " onClick={handleLoginDetails}>Get OTP</button>
                                                                                        </div>
                                                                                    </>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {
                                                                                otpLoading === true ? <h6 style={{ color: "green" }}>OTP Sending...</h6>
                                                                                    : <div class="modal-footer login-footer login-new-footer">
                                                                                        <button className="modal-c-btn" onClick={handleLoginDetails}>Get OTP</button>
                                                                                    </div>
                                                                            }
                                                                        </>
                                                                }
                                                            </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <div>
                                                        <div id="otp">
                                                            <h5>A code has been sent.</h5>
                                                            <label>Please enter the one time password to verify your account</label>
                                                            <div class="d-flex justify-content-start">
                                                                {
                                                                    otp.map((data, ind) =>
                                                                        <input class="text-center form-control rounded" type="text" id="first" maxlength="1" value={data} onChange={(e) => handleOTPfill(e, ind)}
                                                                            autoComplete="off" />)
                                                                }
                                                            </div>
                                                            {
                                                                otpLoading
                                                                    ?
                                                                    <h6 style={{ color: "green" }}>OTP resending...</h6>
                                                                    :
                                                                    <label style={{ color: 'green' }}>Didn't get the code? <button class="resend-btn" onClick={handleLoginDetails}>Resend</button></label>

                                                            }
                                                        </div>
                                                        {
                                                            otpLoading ? null : <div class="modal-footer login-footer">
                                                                <button type="submit" className="modal-c-btn" onClick={handleLoginOTPValidation}>Login</button>
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;