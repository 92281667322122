import { RotatingLines } from "react-loader-spinner";

const Loader = () => {
    return (
        <>
            <div style={{ height: "100vh" }} className="d-flex justify-content-center align-items-center">
                <RotatingLines
                    visible={true}
                    height="50"
                    width="50"
                    color="grey"
                    strokeWidth="4"
                    animationDuration="0.75"
                    strokeColor="#8a992e"
                    ariaLabel="rotating-lines-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
            </div>
        </>
    )
}

export default Loader;