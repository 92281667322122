import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import Loader from '../components/Loader';

const ProductDetails = () => {
    const [data, setData] = useState(null);
    const [model_id, setModelId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const searchParams = useLocation();
    const { state } = searchParams;

    const [priceValue, setPriceValues] = useState(null);
    const getExactProductValue = (price, model_id, variant_id) => {
        setModelId(null);
        setPriceValues(price);
        setModelId(model_id);
        localStorage.setItem('product_variant', variant_id);
    }



    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        let isMounted = true; // Flag to track whether the component is mounted

        const fetchData = async () => {
            try {
                const response = await axios.post('https://cashknock.in/admin/api/getProductById', {
                    id: state.id
                });
                console.log("product_details:", response.data.data);
                if (isMounted) {
                    setData(response.data.data);
                    localStorage.setItem('product_image', response.data.data.image)
                    setLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error);
                    setLoading(false);
                }
            }
        };


        fetchData();
        // Cleanup function (optional)
        return () => {
            isMounted = false; // Mark the component as unmounted
        };
    }, []);

    const navigate = useNavigate();

    const navigateToDetails = (id, category_name) => {
        const exactValuParams = { category_id: id, model_id: model_id, category_name: category_name }
        navigate(`/exact-value`, { state: exactValuParams });
    };

    if (loading) {
        return <Loader />
    }


    return (
        <main id="main" class="pt-4">
            <div id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <div class="breadcrumb-bg">
                        <h2>Selling {loading === false && data.model}</h2>
                        <ol>
                            <li><Link to="/">Home</Link></li>
                            <li>Sell {loading === false && data.category_name}</li>
                            <li>{loading === false && data.model}</li>
                        </ol>
                    </div>
                </div>
            </div>

            <section id="device-detail" class="device-detail">
                <div class="container">
                    <div class="d-detail">
                        <div class="row">
                            <div class="col-lg-4 col-md-5 col-12">
                                <img src={loading === false && data.image} alt="cashknock" />
                            </div>
                            <div class="col-lg-8 col-md-7 col-12">
                                <div class="section-title">
                                    <h2 data-aos="fade-up">{loading === false && data.model}</h2>
                                </div>
                                <div class="storage">
                                    <h4>Choose Your Phone Variant</h4>
                                    <ul>
                                        {loading === false && data.variants && data.variants.map(options => (
                                            <>
                                                <li><a href="javascript:void(0)" onClick={() => getExactProductValue(options.maxprice, options.model_id, options.id)} >{options.variant}</a></li>

                                            </>

                                        ))}
                                    </ul>
                                    {
                                        priceValue !== null ?

                                            <div class="storage mt-5">
                                                <h3>Get upto: <strong>&#x20B9;{priceValue}/-</strong></h3>
                                                <div class="value-btn"><a href="javascript:void(0)" onClick={() => navigateToDetails(data.category_id, data.category_name)}>Get Exact Value <i
                                                    class="bi bi-arrow-right-short"></i></a></div>
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main >
    )
}
export default ProductDetails