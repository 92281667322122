import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Loader from '../components/Loader';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const BrandModel = () => {
    const [data, setData] = useState(null);
    const [brandData, setBrandData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [error, setError] = useState(null);
    // const [searchParams] = useSearchParams();
    const [isMounted, setIsMounted] = useState(true);

    const searchParams = useLocation();
    const { state } = searchParams;
    const category_id = state?.category_id;
    const brand_id = state?.brand_id;
    const partialSlug = state?.partialSlug;

    const navigate = useNavigate();

    const navigateToDetails = (id, url) => {
        const paramsData = { id: id };
        navigate(url, { state: paramsData });
    };

    const navigateToSellBrandWise = () => {
        const paramsData = { category_id: category_id };
        navigate(`/sell-brandwise`, { state: paramsData });
    };

    const navigateToBrandWise = (brand_id, slug) => {
        const paramsData = { category_id: category_id, brand_id: brand_id };
        navigate(`/` + slug, { state: paramsData });
    };

    useEffect(() => {
        setIsMounted(false); // Flag to track whether the component is mounted 
        fetchProducts();
        getBrandById();
        // Cleanup function (optional)
        return () => {
            setIsMounted(false); // Mark the component as unmounted
        };
    }, [brand_id, category_id]);

    const fetchProducts = async () => {
        try {
            const response = await axios.post('https://cashknock.in/admin/api/getProduct', {
                category_id: category_id,
                brand_id: brand_id,
                series_id: 1
            });
            // console.log("data-series:",data?.series);

            setData(response.data.data);
            setLoading(false);
        } catch (error) {
            if (isMounted) {
                setError(error);
                setLoading(false);
            }
        }
    };



    const getBrandById = async () => {
        try {
            const brandResponse = await axios.post('https://cashknock.in/admin/api/getBrandByCategoryId', { category_id: category_id });
            const items = brandResponse.data.data.slice(0, 4);
            const brandCollection = [];
            brandCollection.push(...items);
            // console.log("items:", items);
            setBrandData(items);
            setLoading(false);
        } catch (error) {
            if (isMounted) {
                setError(error);
                setLoading(false);
            }
        }
    };


    const handleKeypress = (event) => {
        const { value } = event.target;
        setSearch(value);
    };

    const navigateToSearch = () => {
        const params = { state: search }
        navigate('/search', { state: params })
    };
    const handlekeydown = (e) => {
        console.log(e);
        if (e.key === "Enter") {
            navigateToSearch();
        }
    }

    //filter the series model
    const [modelData, setModelData] = useState([]);
    useEffect(() => {
        setModelData(data?.models);
    }, [data])

    const [filterNumber, setFilterNumber] = useState(0);
    const handleFilterSeries = (id) => {
        const updatedModelData = data?.models?.filter((element) => {
            setFilterNumber(id);
            return id == element.series_id;
        })
        setModelData(updatedModelData);
    }
    const handleReset = () => {
        setModelData(data.models);
        setFilterNumber(0);
    }

    if (loading) {
        return <Loader />
    }




    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Cashknock - Sell Device</title>
                </Helmet>
            </HelmetProvider>

            <main id="main" class="pt-4">
                <section id="sell-form" class="sell-form">
                    <div class="container">
                        <div class="sell-form-bg brand_form_1">
                            <div class="row d-flex justify-content-center">
                                <div class="col-lg-12 col-md-6">
                                    <div class="section-title select_model_heading">
                                        <h2 data-aos="fade-up">Select your model brand</h2>
                                    </div>
                                    <div class="d-flex justify-content-center">
                                        <div class="search-bar mt-2 search_brand">
                                            <input type="text" placeholder="search model" value={search} onChange={handleKeypress} onKeyDown={handlekeydown}/>
                                            <button onClick={navigateToSearch}  >go!</button>
                                        </div>
                                    </div>
                                    <h4 class="mt-2 mb-2 text-center brand_choose"><strong>Or Choose A Brand Below:</strong></h4>
                                    <div class="dp-brands brand_list">
                                        <ul className='text-center text-md-left'>
                                            {
                                                loading == false && brandData && brandData.map(option => (
                                                    <li className='col-6 col-md-3'><a href="javascript:void(0)" onClick={() => navigateToBrandWise(option.id, option.category_name.toLowerCase() + "/" + option.url.toLowerCase())}>
                                                        <img src={option.image} alt={option.brand} key={option.id} /></a></li>
                                                ))
                                            }

                                            <li class="no-border"><a href="javascript:void(0)" onClick={() => navigateToSellBrandWise()}>Click to see more brands <i class="bi bi-arrow-right-circle-fill"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {loading == false && data?.series && data?.series.length > 0 ?
                    <div id="select_series">
                        <div class="container">
                            <h4>Select Series</h4>
                            <div class="container">
                                <div class="row g-2">
                                    {
                                        loading == false && data?.series && data?.series.map(series => (
                                            <div class="col-6 col-sm-4 col-md-3">
                                                <div className={filterNumber == series.id ? "series_inner series_inner_active" : "series_inner"}>
                                                    <h6><a onClick={() => handleFilterSeries(series.id)}>{series.series}</a></h6>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div class="col-6 col-sm-4 col-md-3">
                                        <div className={filterNumber == 0 ? "series_inner series_inner_active" : "series_inner"}>
                                            <h6><a onClick={handleReset}>See all</a></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                }

                <section id="d-gallery" class="d-gallery">
                    <div class="container" >
                        {
                            loading == false && data?.models ?
                                <div class="row device-gallery">
                                    {
                                        modelData?.map(option => (
                                            <div class="col-lg-2 col-md-3 col-6">
                                                <div class="product-img">
                                                    <a href="javascript:void(0)" onClick={() => navigateToDetails(option.id, option.url)}>
                                                        <img src={option.image} class="img-fluid" alt="" />
                                                        <h4>{option.model}</h4>
                                                    </a> </div>
                                            </div>
                                        ))

                                    }
                                </div>
                                : <div class="row device-gallery d-flex justify-content-center">
                                    <div className='col-md-6'>
                                        <img src='../assets/img/no-data.webp' className='img-fluid' />
                                    </div>
                                </div>
                        }
                    </div>
                </section>
            </main >
        </>
    )
}
export default BrandModel;