import React from "react";
import { Link } from "react-router-dom";
const SellDevice = () => {
    return (
        <main id="main">
            <div id="breadcrumbs" class="breadcrumbs">
                <div class="container">
                    <div class="breadcrumb-bg">
                        <h2>Sell Old Mobile Phones At Best Price</h2>
                        <ol>
                            <li><Link to="/">Home</Link></li>
                            <li>Sell Your Old Phone</li>
                        </ol>
                    </div>
                </div>
            </div>
            <section id="sell-form" class="sell-form">
                <div class="container">
                    <div class="sell-form-bg">
                        <div class="row d-flex justify-content-end">
                            <div class="col-lg-7 col-md-6">
                                <div class="section-title">
                                    <h2 data-aos="fade-up">Select your model brand</h2>
                                </div>
                                <div class="search-bar mt-2">
                                    <input type="text" placeholder="search model" />
                                    <button>go!</button>
                                </div>
                                <h4 class="mt-4 mb-2"><strong>Or Choose A Brand Below:</strong></h4>
                                <div class="dp-brands">
                                    <ul>
                                        <li><a href="sell-brandwise.html"><img src="assets/img/brands/b-8.webp" alt="cashknock mobile scroll" /></a></li>
                                        <li><a href="sell-brandwise.html"><img src="assets/img/brands/b-2.webp" alt="cashknock mobile scroll" /></a></li>
                                        <li><a href="sell-brandwise.html"><img src="assets/img/brands/b-6.webp" alt="cashknock mobile scroll" /></a></li>
                                        <li><a href="sell-brandwise.html"><img src="assets/img/brands/b-4.webp" alt="cashknock mobile scroll" /></a></li>
                                        <li class="no-border"><a href="sell-brandwise.html">Click to see more brands <i class="bi bi-arrow-right-circle-fill"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about" class="about">
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <div class="col-xl-9 col-lg-10">
                            <div class="section-title" data-aos="fade-up">
                                <h2>Why Us?</h2>
                                <p>We are a dedicated bunch of professionals aligned with your goal of maximum satisfaction.</p>
                               
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-lg-3 col-md-4 col-6">
                            <div class="y-feature"> <img src="assets/img/why-us/1.png" alt="why-us feature" />
                                <h4>One-Stop Solution</h4>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                            <div class="y-feature"> <img src="assets/img/why-us/2.png" alt="why-us feature" />
                                <h4>Trained Professionals</h4>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                            <div class="y-feature"> <img src="assets/img/why-us/3.png" alt="why-us feature" />
                                <h4>Amazing Prices</h4>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                            <div class="y-feature"> <img src="assets/img/why-us/4.png" alt="why-us feature" />
                                <h4>Quick & Hassle-free Payment</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="testimonials" class="testimonials">
                <div class="container position-relative" data-aos="fade-up">
                    <div class="section-title text-white">
                        <h2 class="text-white" data-aos="fade-up">Hear From Our Customers!</h2>
                        <p data-aos="fade-up">Whatever you’re looking to sell, we can help. Check out our top seller gadgets.</p>
                    </div>
                    <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="testimonial-item"> <img src="assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="" />
                                    <h3>Saul Goodman</h3>
                                    <h4>Ceo &amp; Founder</h4>
                                    <p> <i class="bx bxs-quote-alt-left quote-icon-left"></i> Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper. <i class="bx bxs-quote-alt-right quote-icon-right"></i> </p>
                                </div>
                            </div>
                           
                            <div class="swiper-slide">
                                <div class="testimonial-item"> <img src="assets/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="" />
                                    <h3>Sara Wilsson</h3>
                                    <h4>Designer</h4>
                                    <p> <i class="bx bxs-quote-alt-left quote-icon-left"></i> Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa. <i class="bx bxs-quote-alt-right quote-icon-right"></i> </p>
                                </div>
                            </div>
                           

                            <div class="swiper-slide">
                                <div class="testimonial-item"> <img src="assets/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="" />
                                    <h3>Jena Karlis</h3>
                                    <h4>Store Owner</h4>
                                    <p> <i class="bx bxs-quote-alt-left quote-icon-left"></i> Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim. <i class="bx bxs-quote-alt-right quote-icon-right"></i> </p>
                                </div>
                            </div>
                           

                            <div class="swiper-slide">
                                <div class="testimonial-item"> <img src="assets/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="" />
                                    <h3>Matt Brandon</h3>
                                    <h4>Freelancer</h4>
                                    <p> <i class="bx bxs-quote-alt-left quote-icon-left"></i> Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam. <i class="bx bxs-quote-alt-right quote-icon-right"></i> </p>
                                </div>
                            </div>
                           

                            <div class="swiper-slide">
                                <div class="testimonial-item"> <img src="assets/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="" />
                                    <h3>John Larson</h3>
                                    <h4>Entrepreneur</h4>
                                    <p> <i class="bx bxs-quote-alt-left quote-icon-left"></i> Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid. <i class="bx bxs-quote-alt-right quote-icon-right"></i> </p>
                                </div>
                            </div>
                           

                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
            </section>
            <section id="faq" class="faq section-bg">
                <div class="container">
                    <div class="section-title">
                        <h2 data-aos="fade-up">F.A.Q</h2>
                        <p data-aos="fade-up">Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
                    </div>
                    <div class="faq-list">
                        <ul>
                            <li data-aos="fade-up"> <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" class="collapse" data-bs-target="#faq-list-1">Non consectetur a erat nam at lectus urna duis? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-1" class="collapse show" data-bs-parent=".faq-list">
                                    <p> Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non. </p>
                                </div>
                            </li>
                            <li data-aos="fade-up" data-aos-delay="100"> <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" class="collapsed">Feugiat scelerisque varius morbi enim nunc? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-2" class="collapse" data-bs-parent=".faq-list">
                                    <p> Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui. </p>
                                </div>
                            </li>
                            <li data-aos="fade-up" data-aos-delay="200"> <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" class="collapsed">Dolor sit amet consectetur adipiscing elit? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-3" class="collapse" data-bs-parent=".faq-list">
                                    <p> Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis </p>
                                </div>
                            </li>
                            <li data-aos="fade-up" data-aos-delay="300"> <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" class="collapsed">Tempus quam pellentesque nec nam aliquam sem et tortor consequat? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-4" class="collapse" data-bs-parent=".faq-list">
                                    <p> Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit turpis cursus in. </p>
                                </div>
                            </li>
                            <li data-aos="fade-up" data-aos-delay="400"> <i class="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" class="collapsed">Tortor vitae purus faucibus ornare. Varius vel pharetra vel turpis nunc eget lorem dolor? <i class="bx bx-chevron-down icon-show"></i><i class="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-5" class="collapse" data-bs-parent=".faq-list">
                                    <p> Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae ultricies leo integer malesuada nunc vel. Tincidunt eget nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed. Ut venenatis tellus in metus vulputate eu scelerisque. </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section class="counter-wrapper">
                <div class="counter-inner">
                    <div class="container">
                        <div class="row g-0">
                            <div class="col-6 col-lg-3">
                                <div class="py-5 text-center text-white">
                                    <div> <i class="bi bi-emoji-smile"></i> </div>
                                    <div class="py-2 count"> <span id="count1">345</span> </div>
                                    <div> Happy customers </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-3">
                                <div class="py-5 text-center text-white">
                                    <div> <i class="bi bi-cash-coin"></i> </div>
                                    <div class="py-2 count"> <span id="count2">120000</span>+ </div>
                                    <div> Cash paid to our valuable customers </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="offers" class="offers">
                <div class="container" data-aos="fade-up">
                    <div class="section-title">
                        <h2 data-aos="fade-up">Best Offers</h2>
                        <p data-aos="fade-up">Selling gadgets made easier! Knock it out! Knock out your cell phones, laptops or other devices</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                            <div class="offer-sec"><img src="assets/img/offers/offer-1.jpg" alt="cashknock offers" /></div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                            <div class="offer-sec"><img src="assets/img/offers/offer-2.jpg" alt="cashknock offers" /></div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                            <div class="offer-sec"><img src="assets/img/offers/offer-3.jpg" alt="cashknock offers" /></div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="clients" class="clients">
                <div class="container" data-aos="fade-up">
                    <div class="clients-slider swiper">
                        <div class="swiper-wrapper align-items-center">
                            <div class="swiper-slide"><img src="assets/img/clients/client-1.png" class="img-fluid" alt="" /></div>
                            <div class="swiper-slide"><img src="assets/img/clients/client-2.png" class="img-fluid" alt="" /></div>
                            <div class="swiper-slide"><img src="assets/img/clients/client-3.png" class="img-fluid" alt="" /></div>
                            <div class="swiper-slide"><img src="assets/img/clients/client-4.png" class="img-fluid" alt="" /></div>
                            <div class="swiper-slide"><img src="assets/img/clients/client-5.png" class="img-fluid" alt="" /></div>
                            <div class="swiper-slide"><img src="assets/img/clients/client-6.png" class="img-fluid" alt="" /></div>
                            <div class="swiper-slide"><img src="assets/img/clients/client-7.png" class="img-fluid" alt="" /></div>
                            <div class="swiper-slide"><img src="assets/img/clients/client-8.png" class="img-fluid" alt="" /></div>
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
            </section>


        </main>
    )
}
export default SellDevice