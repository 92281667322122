import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Upload } from 'antd';

export default function UserProfile() {

    const [userData, setUserData] = useState();
    const [userName, setUserName] = useState("");
    const [profileImage, setProfileImage] = useState("");
    const [previewImage,setPreviewImage] = useState("/assets/img/usernoprofile/blank-profile-picture-973460_1280.png");

    const [changeBtn, setChangeBtn] = useState(false);

    const id = localStorage.getItem("user_id");

    const fetchUserData = async () => {
        try {
            const res = await axios.post("https://cashknock.in/admin/api/getUserById", { id: id });
            setUserData(res.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchUserData();
    }, [])

    useEffect(() => {
        if (userData) {
            setUserName(userData?.name);
            setPreviewImage(userData?.image);
        }
    }, [userData]);

    const handleNameChange = (e) => {
        setUserName(e.target.value);
        setChangeBtn(true);
    }

    const handleProfileImage = (e) => {
        setProfileImage(e.target.files[0]);
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
        setChangeBtn(true);
    }

    const handleSaveChanges = async () => {
        try {
            console.log(profileImage);

            const formData = new FormData();
            formData.append('id', id);
            formData.append('name', userName);
            formData.append('image', profileImage);

            const res = await axios.post("https://cashknock.in/admin/api/updateProfile", formData);
            if (res.data.status === 1) {
                window.location.reload();
                localStorage.setItem("name", userName);
            }
            console.log(res.data);
        } catch (err) {
            console.log(err);
        }
    }

    // console.log(userData);


    return (
        <div className='user-profile bg-white p-4 p-md-5 my-4'>
            <div class="container rounded bg-white">
                <div class="row ">
                    <div class="col-md-5 border-right mt-md-5">
                        <div class=" d-flex flex-column align-items-center text-center p-3 py-5">

                            <div className='profile'>
                                <div className='profile-image'>
                                    <img class="rounded-circle " src={previewImage} />
                                </div>
                                <span className='upload-profile'>
                                    <label htmlFor="input_profile" >
                                        <i class="bi bi-camera"></i>
                                    </label>
                                    <input type="file" id="input_profile" accept='image/jpeg, image/jpg, image/png' className='d-none' onChange={handleProfileImage} />
                                </span>
                            </div>

                            <span class="font-weight-bold">{userData?.name}</span>
                            <span class="text-black-50">{userData?.email}</span>

                        </div>
                    </div>

                    <div class="col-md-7 border-right">
                        <div class="p-3 py-5 w-100">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <h4 class="text-right d-none d-md-block">Profile Settings</h4>
                            </div>
                            <div class="row mt-2 ">
                                <div class="col-md-12"><label class="labels">Name</label><input name="name" type="text" class="form-control" placeholder="enter your name" value={userName} autoComplete='off' onChange={handleNameChange} /></div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-12 pe-none"><label class="labels">Mobile Number</label><input type="text" class="form-control" placeholder="enter phone number" value={userData?.mobile_no} /></div>

                                <div class="col-md-12 pe-none"><label class="labels">Email ID</label><input type="text" class="form-control" placeholder="enter email id" value={userData?.email} /></div>
                            </div>
                            {
                                changeBtn ? <>
                                    <h6 style={{color:"red"}} className='mt-5'>please save all the changes*</h6>
                                    <div class="text-left"><button class="btn btn-primary profile-button" type="button" onClick={handleSaveChanges}>Save Profile</button></div>
                                </> : <div class="mt-5 text-left"><button class="btn btn-primary profile-button pe-none opacity-75" type="button" onClick={handleSaveChanges}>Save Profile</button></div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
